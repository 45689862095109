@use "variables.scss" as *;

html,
body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.header-text {
  @include header();
}

.title-text {
  font-size: 16px;
  font-weight: 500;
}

.subtitle-text {
  color: var(--subtitle-text-color);
  font-size: 14px;
  font-weight: 400;
}

.content {
  line-height: 1.5;
  height: 100%;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-dropdownbutton-content {
  .dx-list-item {
    display: block;
  }
}

.dx-datagrid-header-panel {
  padding-left: 0 !important;
}

.dx-datagrid-search-panel {
  margin-left: 0 !important;
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.view-wrapper-scroll
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

.embedded.embedded {
  .layout-header,
  .dx-swatch-additional,
  .dx-drawer-shader {
    display: none;
  }

  .dx-drawer-content {
    padding: initial !important;
  }

  .dx-drawer-panel-content,
  .dx-drawer-panel-content .dx-overlay-content {
    width: initial !important;
  }
}

.separator {
  height: 24px;
  width: 1px;
  background: var(--border-color);

  ::ng-deep .dx-toolbar-menu-section & {
    height: 1px;
    width: auto;
  }
}

.view-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dx-card.details-card {
  border: none;
}

.dx-texteditor-label {
  height: auto;

  .dx-label {
    line-height: 1;
  }
}

.dx-list-item-content {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.dx-form.plain-styled-form {
  &.dx-form
    > .dx-layout-manager.dx-layout-manager-one-col
    .dx-field-item.dx-last-row:not(.dx-last-col) {
    padding-bottom: 0;
  }

  .dx-layout-manager .dx-field-item {
    padding-bottom: 0;

    &:not(.dx-first-col) {
      padding-left: 0;
    }

    &.contact-fields-group {
      padding: 15px 0;
    }
  }

  &.view-mode {
    .accent {
      .dx-texteditor.form-editor .form-editor-input {
        color: var(--accent-color);
      }
    }

    .dx-texteditor,
    .dx-texteditor.dx-editor-filled,
    .dx-texteditor.form-editor {
      pointer-events: none;

      .form-editor-input {
        color: var(--texteditor-edit-color);
      }

      &,
      & > * {
        background-color: transparent;
      }

      &,
      & *,
      &::after {
        border-color: transparent;
        opacity: 1;
      }
    }
  }
}

.dx-tooltip-wrapper:has(.planning-calendar-appointment-tooltip) {
  .dx-popup-content,
  .dx-popup-normal {
    max-height: 600px !important;
    border-radius: 8px;
  }
}

@mixin status($status-color) {
  color: $status-color;

  &.dx-texteditor,
  &.dx-texteditor.dx-editor-filled {
    .status-input.status-editor-input {
      color: $status-color;
    }
  }

  &::before {
    background: $status-color;
  }
}

.status-commission {
  @include status(#03a9f4);
}

.status-salaried {
  @include status(#2eb52c);
}

.status-terminated {
  @include status(#de8e8c);
}

.status-indicator-open {
  @include status(#505ed9);
}

.status-indicator-in-progress {
  @include status(#34aa95);
}

.status-indicator-deferred {
  @include status(#969696);
}

.status-indicator-completed {
  @include status(#2b9029);
}

.status-indicator-low {
  @include status(#d3a268);
}

.status-indicator-normal {
  @include status(#6fbaca);
}

.status-indicator-high {
  @include status(#ff5722);
}
