@use "../node_modules/devextreme/scss/widgets/material/colors" as * with (
  $color: "orange",
  $mode: "light"
);
@use "../node_modules/devextreme/scss/widgets/material/sizes" as * with (
  $size: "compact"
);
@use "../node_modules/devextreme/scss/widgets/material/button/mixins" as *;
@use "../node_modules/devextreme/scss/widgets/material/textEditor/colors" as *;
@use "../node_modules/devextreme/scss/widgets/material/fieldset/sizes" as *;
@use "../node_modules/devextreme/scss/widgets/material/list/sizes" as *;
@use "../node_modules/devextreme/scss/widgets/material/toolbar/sizes" as
  toolbar_sizes;

@forward "../node_modules/devextreme/scss/widgets/material/colors";
@forward "../node_modules/devextreme/scss/widgets/material/textEditor/colors";

@use "sass:math";
@use "sass:color";

$toolbar-height: toolbar_sizes.$material-toolbar-height;
$header-toolbar-vertical-padding: 10px;
$toolbar-vertical-padding: 16px;
$content-padding: 16px;
$side-panel-min-width: 48px;
$side-panel-background: darken($base-bg, 5);
$background-color: $base-bg;
$accent-color: $base-accent;
$background-gray-color: color.change($base-text-color, $alpha: 0.04);
$toolbar-margin-bottom: $content-padding;
$texteditor-edit-color: $texteditor-color;
$filled-texteditor-input-horizontal-padding: $material-field-value-horizontal-padding;
$list-padding-left: $material-list-item-horizontal-padding;
$footer-height: 70px;
$footer-left-padding: math.div(
  ($side-panel-min-width - $material-base-icon-size),
  2
);

@mixin separator() {
  .separator {
    height: 1px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    background: $base-border-color;

    ::ng-deep .dx-toolbar-menu-section & {
      height: 1px;
      width: auto;
    }
  }
}

@mixin header {
  font-size: 22px;
  font-weight: 500;
  padding-right: 25px;
}

@mixin message-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: $texteditor-label-color;
}

@mixin message-text {
  padding-top: 5px;
  line-height: 21px;
}

@mixin messages-content {
  .messages-content {
    padding: 20px;
    border-top: 1px solid $base-border-color;
    background-color: $side-panel-background;
  }
}

@mixin status($status-color) {
  color: $status-color;

  &.dx-texteditor {
    .status-input.status-editor-input {
      color: $status-color;
    }
  }

  &::before {
    background: $status-color;
  }
}
